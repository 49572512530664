import { Component, OnInit, ViewChild } from '@angular/core';
import { EUserActionsModalType } from '../../../Interfaces/Enumerators/EUserActionsModalType';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InteractionService } from '../../../interaction.service'
import { ISkinConfig } from '../../../Interfaces/SkinConfig.interface';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ConfigService } from 'src/app/config.service';
import { Login } from 'src/app/login.interface';
import { TranslateService } from '@ngx-translate/core';
import { RecoveryPasswordRequestModel } from 'src/app/requestModels/RecoveryPasswordRequestModel';
import { EEMailLanguage } from 'src/app/Interfaces/Enumerators/EEMailLanguage';
import { passwordMatchValidator } from '../../../Helpers/PasswordMatchValidator'
import { MatCheckbox } from '@angular/material/checkbox';
import { User } from 'src/app/user.interface';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss']
})
export class UserActionsComponent implements OnInit {


  // BASE
  modalType = EUserActionsModalType;
  currentModalType: EUserActionsModalType = this.modalType.NONE;
  configLoaded: boolean = false;
  skinConfig: ISkinConfig;

  // LOGIN MODAL
  loginForm: FormGroup;
  show_password: boolean;


  // RECOVERY PASSWORD MODAL
  forgotPasswordForm: FormGroup;


  // REGISTER MODAL
  @ViewChild('confirmCheckbox', { static: true }) confirmCheckbox: MatCheckbox;

  registerForm: FormGroup;
  refCodeForm: FormGroup;
  registerLoading: boolean = false;
  refCode: string = '';
  conditionTermConfirmation: boolean = false;


  //CHANGE PASSWORD MODAL
  changePasswordForm: FormGroup;
  userAdmin: User;


  constructor(private interactionService: InteractionService, private route: ActivatedRoute,
    private modalsService: ModalsService, private configService: ConfigService, private translate: TranslateService
  ) {
    modalsService.registerUserActionsModal(this);
  }



  async ngOnInit() {
    this.interactionService.refCode.subscribe(data => {
      this.refCode = data;
    })

    this.interactionService.skinConfig.subscribe(data => {
      this.skinConfig = data;
      this.configLoaded = true;
    });

    this.loginForm = new FormGroup({
      'username': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required])
    });

    this.forgotPasswordForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email])
    });

    this.registerForm = new FormGroup({
      'username': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required, Validators.minLength(8)]),
      'confirmPassword': new FormControl(null, [Validators.required]),
      'checkBox': new FormControl(false, [Validators.requiredTrue])
    }, {
      validators: passwordMatchValidator('password', 'confirmPassword')
    });


    this.refCodeForm = new FormGroup({
      refCode: new FormControl(null)
    });

    this.changePasswordForm = new FormGroup({
      'currentPassword': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'password': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'confirmPassword': new FormControl('', [Validators.required])
    }, {
      validators: passwordMatchValidator('password', 'confirmPassword')
    });

  }




  // BASE METHODS
  open(modalType: EUserActionsModalType) {
    this.currentModalType = modalType
  }

  close() {
    this.currentModalType = EUserActionsModalType.NONE;
    this.loginForm.reset();
    this.forgotPasswordForm.reset();
    this.registerForm.reset();
    this.refCodeForm.reset();
    this.changePasswordForm.reset();
    this.conditionTermConfirmation = false;
    this.confirmCheckbox.checked = false;
  }


  // LOGIN MODAL

  login(username: string = null, password: string = null): void {
    username = username ?? this.loginForm.get('username').value;
    password = password ?? this.loginForm.get('password').value;

    this.configService.getLogin(username, password)
      .subscribe((data: Login) => {
        this.interactionService.setLoginData(data);
        this.interactionService.setIsLogged(true);
        this.interactionService.setUserAdmin(data.user);
        localStorage.setItem('currentUser', data.user.id);
        localStorage.setItem('currentUserToken', data.token);

        if (data.user.country == null) {
          localStorage.setItem('currentCountry', 'Brazil');
        } else {
          localStorage.setItem('currentCountry', data.user.currencyCode);
        }
        this.configService.setGlobalCurrency();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.error-login'), this.translate.instant('pg-app-component.wrong-user-password'), true)
      })
    this.close();

  }

  showPassword() {
    this.show_password = !this.show_password;
  }


  // RECOVERY PASSWORD MODAL
  forgetPassword() {
    this.currentModalType = EUserActionsModalType.RecoveryPassword;
  }

  recoverPassword() {
    this.interactionService.setIsLogged(false);
    const languageOfStorage = localStorage.getItem('language');
    const email = this.forgotPasswordForm.get('email').value;
    const recoveryRequestModel = new RecoveryPasswordRequestModel(email, EEMailLanguage[languageOfStorage]);
    this.configService.sendRecoveryPassword(recoveryRequestModel)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.register-success'), this.translate.instant('pg-app-component.sent-credentials'), false);
        this.close();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.not-sent'), this.translate.instant('pg-app-component.not-sent-info') + ' (' + error.error + ')', true);
      })
  }

  //REGISTER MODAL
  register() {
    this.registerLoading = true;
    const username: string = this.registerForm.get('username').value;
    const email: string = this.registerForm.get('email').value;
    const password: string = this.registerForm.get('password').value;
    let refCode: string = this.refCode != '' ? this.refCode : this.refCodeForm.get('refCode').value ?? "";


    let newUser: User = {
      login: username,
      name: '',
      lastName: '',
      email: email,
      jurisdictionId: 7,
      parentId: this.skinConfig.club,
      password: password,
      country: '',
      city: '',
      deleted: false,
      commission: 0,
      jurisdiction: null,
      userPermission: null,
      credit: 0,
      id: 0,
      children: null,
      bank: '',
      agency: '',
      account: '',
      registrationDate: new Date(),
      currencyCode: "BRL",
      skinPartnerId: this.skinConfig.hubID
    };
    this.configService.createUser(newUser, refCode)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.successfull-register'), this.translate.instant('pg-app-component.successfull-register-info'), false)
        this.login(username, password)
        this.close();
        this.registerLoading = false;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-new-user.' + error.error), true);
        this.registerLoading = false;
      })
  }

  ToggleConditionTermConfirmation() {
    this.conditionTermConfirmation = !this.conditionTermConfirmation;
    if (!this.conditionTermConfirmation && !this.registerForm.invalid) {
      this.registerForm.setErrors({ required: true })

    }
  }


  // CHANGE PASSWORD MODAL
  changePassword() {
    const currentPassword = this.changePasswordForm.get('currentPassword').value;
    const newPassword = this.changePasswordForm.get('password').value;

      this.interactionService.userAdmin.subscribe(data => {
       this.userAdmin = data;
      })

    this.configService.updatePassword(this.userAdmin.id, this.userAdmin.login, true, newPassword, currentPassword)
      .subscribe(data => {
        this.close();
        this.userAdmin.password = newPassword;
        this.modalsService.openNotificationModal(this.translate.instant("pg-settings.change-made"), this.translate.instant("pg-settings.change-made-info"), false);
        this.interactionService.setUserAdmin(this.userAdmin);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-settings.change-error"), this.translate.instant("pg-settings.change-error-info"), true);
      });

  }
}
